import firebase from "firebase/app"
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

let config = {}

if(process.env.NODE_ENV === 'development'){
  console.log('develop');
  config = {
    apiKey: "AIzaSyB52ZVWbCDjITo18aVGs9e26pbbOUtTCjA",
    authDomain: "slapp-develop.firebaseapp.com",
    projectId: "slapp-develop",
    storageBucket: "slapp-develop.appspot.com",
    messagingSenderId: "416508608529",
    appId: "1:416508608529:web:0a82b358e55a9fe62d71b0"
  };
}else{
  console.log('production');
  config = {
    apiKey: "AIzaSyAkWNorj-N8ZHNPjkslfUJ_iUj2mY8Eujw",
    authDomain: "cgt-prod.firebaseapp.com",
    databaseURL: "https://cgt-prod.firebaseio.com",
    projectId: "cgt-prod",
    storageBucket: "cgt-prod.appspot.com",
    messagingSenderId: "143553239153",
    appId: "1:143553239153:web:18ec9b0615a1eaa89123d0",
    measurementId: "G-KL8DR4BCKX"
  };
}



!firebase.apps.length && firebase.initializeApp(config)

const googleProvider = new firebase.auth.GoogleAuthProvider()

const firestore = firebase.firestore()

export {
  firebase,
  firestore,
  googleProvider,
  config
}